import {
  ReactionTypeType as CommentReactions,
  RepliesReactionTypeType as ReplyReactions,
  Type as MarkedLabelType,
  IconTypeType as ReactionIcon,
  QueryV2,
  Category as ApiCategory,
  Role,
} from '@wix/ambassador-comments-v1-category/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  createCategory as apiCreateCategory,
  queryCategory as apiQueryCategory,
  getCategory as apiGetCategory,
  updateCategory as apiUpdateCategory,
} from '@wix/ambassador-comments-v1-category/http';
import { assertFields } from '../../ts-utils';
import { fromServerCategory } from './widget-settings-service.mappers';
import { Category } from './widget-settings-service.types';
import { BLOG_APP_DEF_ID, WIX_COMMENTS_WIDGET_APP_DEF_ID } from '~/constants/app-def-ids';

export const createWidgetSettingsService = ({
  httpClient,
  isBlogComments,
}: {
  httpClient: IHttpClient;
  isBlogComments: boolean;
}) => {
  const appDefId = isBlogComments ? BLOG_APP_DEF_ID : WIX_COMMENTS_WIDGET_APP_DEF_ID;
  const service = {
    createCategory: createCategory(httpClient, appDefId),
    queryCategories: queryCategories(httpClient, appDefId),
    updateCategory: updateCategory(httpClient),
    getCategory: getCategory(httpClient),
  };

  return service;
};

const createCategory = (httpClient: IHttpClient, appDefId: string | undefined) => async () => {
  const defaults: Partial<ApiCategory> = {
    name: 'Comments',
    guestCommenting: true,
    guestReactions: true,
    reactionType: CommentReactions.REACTIONS,
    repliesReactionType: ReplyReactions.REACTIONS,
    mainReaction: { iconType: ReactionIcon.THUMBS_UP },
    ratingsSettings: { ratingsEnabled: false, ratingRequired: false },
    markedCommentLabelType: MarkedLabelType.FEATURED,
    permissionsSettings: {
      createComment: { role: Role.ALL },
      createReply: { role: Role.ALL },
      createReact: { role: Role.ALL },
    },
  };
  const { data } = await httpClient.request(
    apiCreateCategory({ category: { ...defaults, appId: appDefId } }),
  );

  return data.category?.id ?? undefined;
};

const queryCategories = (httpClient: IHttpClient, appDefId: string | undefined) =>
  async function (request: QueryV2 = {}) {
    const { data } = await httpClient.request(
      apiQueryCategory({
        query: {
          ...request,
          filter: {
            appId: appDefId,
          },
        },
      }),
    );
    assertFields(data, ['categories'], 'apiQueryCategory reponse');

    return data.categories.map(fromServerCategory);
  };

const getCategory = (httpClient: IHttpClient) => async (categoryId: string) => {
  const { data } = await httpClient.request(apiGetCategory({ categoryId }));
  assertFields(data, ['category'], 'apiGetCategory reponse');

  return fromServerCategory(data.category);
};

const updateCategory = (httpClient: IHttpClient) =>
  async function ({
    currentCategory,
    update,
  }: {
    currentCategory: Category;
    update: Omit<ApiCategory, 'revision' | 'id'>;
  }) {
    //  This API has some quirks that you should be aware of.
    // 1. It requires a 'mask' to be sent with the request, indicating which fields are being updated.
    // any fields not in the mask will be ignored, even if they're present in the request body.
    // 2. Certain fields ('id', 'revision', 'name', 'guestCommenting', 'guestReactions') are always required,
    // even if their values are not being updated.
    const { id, revision, name, guestCommenting, guestReactions } = currentCategory;

    const { data } = await httpClient.request(
      apiUpdateCategory({
        category: {
          ...{ id, revision, name, guestCommenting, guestReactions },
          ...update,
        },
        mask: Object.keys(update),
      }),
    );
    assertFields(data, ['category'], 'apiUpdateCategory reponse');

    return fromServerCategory(data.category);
  };
