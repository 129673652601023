import { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  registerCommentsWidget as apiRegisterCommentsWidget,
  queryCommentsWidget as apiQueryCommentsWidget,
} from '@wix/ambassador-comments-categories-v1-comments-widget/http';
import { RegisterCommentsWidgetRequest } from '@wix/ambassador-comments-categories-v1-comments-widget/types';

export const createWidgetRegistryService = ({ httpClient }: { httpClient: IHttpClient }) => {
  const service = {
    registerWidget: registerWidget(httpClient),
    queryWidgets: queryWidgets(httpClient),
  };

  return service;
};

const registerWidget =
  (httpClient: IHttpClient) => async (request: RegisterCommentsWidgetRequest) =>
    httpClient.request(apiRegisterCommentsWidget(request));

const queryWidgets = (httpClient: IHttpClient) => async () => {
  const { data } = await httpClient.request(apiQueryCommentsWidget({ query: {} }));
  return data.commentsWidgets ?? [];
};
