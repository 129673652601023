import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { getPublicDataStoreSnapshot } from './get-public-data-store-snapshot';
import { map } from 'lodash';
import { createWidgetSettingsService } from '~/services/widget-settings-service';
import { BLOG_APP_DEF_ID } from '~/constants/app-def-ids';

export const tryFixWidgetCategory = async ({
  editorSDK,
  flowAPI,
}: {
  editorSDK: FlowEditorSDK;
  flowAPI: EditorScriptFlowAPI;
}) => {
  try {
    /*
    We check if we have widgets who dont have assigned category.
    These are either widgets which were created before category per widget flow and have resourceId bound to their compId
    or these are widgets where category creation failed.
    Next we check if site has any categories, if not we deduce that category creation failed and we need to create categories for each widgets.
    */

    const dataStoreSnapshot = await getPublicDataStoreSnapshot({ editorSDK, flowAPI });

    const widgetsToFix = map(dataStoreSnapshot, (store, key) => ({
      compId: key,
      categoryId: store?.getCategoryId(),
      needsFix: store?.getCategoryIdNeedsFixing(),
      store,
    })).filter((widget) => !widget.categoryId || widget.needsFix);

    if (widgetsToFix.length) {
      const widgetSettingsService = createWidgetSettingsService({
        httpClient: flowAPI.httpClient,
        isBlogComments: flowAPI.environment.appDefinitionId === BLOG_APP_DEF_ID,
      });
      const categories = await widgetSettingsService.queryCategories();
      if (categories.length > 0) {
        return;
      }
      widgetsToFix.forEach(({ store }) => {
        store?.setCategoryIdNeedsFixing(true);
      });
    }
  } catch (e) {
    const error = e instanceof Error ? e : new Error(`Unknown error in tryFixWidgetCategory.`);

    console.log('Failed to tryFixWidgetCategory', error);

    flowAPI.reportError(error);
  }
};
