import { Category as ApiCategory } from '@wix/ambassador-comments-v1-category/types';
import { assertDefined } from '../../ts-utils';
import { Category } from './widget-settings-service.types';

export const fromServerCategory = (apiCategory: ApiCategory): Category => {
  assertDefined(apiCategory.id);
  assertDefined(apiCategory.revision);

  return {
    id: apiCategory.id,
    revision: apiCategory.revision,
    name: apiCategory.name ?? 'default',
    guestCommenting: apiCategory.guestCommenting ?? undefined,
    guestReactions: apiCategory.guestReactions ?? undefined,
    mainReaction: apiCategory.mainReaction?.iconType ?? undefined,
    markedCommentLabelType: apiCategory.markedCommentLabelType,
    customMarkedCommentLabel: apiCategory.customMarkedCommentLabel ?? undefined,
    reactionType: apiCategory.reactionType,
    repliesReactionType: apiCategory.repliesReactionType,
    ratingsSettings: apiCategory.ratingsSettings,
    permissionsSettings: apiCategory.permissionsSettings,
  };
};
